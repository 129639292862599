import { gql } from 'graphql-request'

export const getRecipientForMedicalOpinion = gql`
  query getRecipientForMedicalOpinion($card_number: String) {
    applications {
      medicalOpinion {
        recipient(card_number: $card_number) {
          name
          card_number
          birth_date
          cpf
        }
      }
    }
  }
`

export const getMedicalSpecialties = gql`
  query getMedicalSpecialties($crm: String, $contract: String) {
    applications {
      medicalOpinion {
        medicalSpecialties(crm: $crm, contract: $contract) {
          cbo
          code
          description
        }
      }
    }
  }  
`

export const getMedicalOpinions = gql`
  query getMedicalOpinions($cbo: String, $card_number: String, $contract: String) {
    applications {
      medicalOpinion {
        medicalOpinions(cbo: $cbo, card_number: $card_number, contract: $contract) {
          submitted_at
          card_number
          recipient_name
          specialty_cbo
          specialty_description
          medical_opinion_number
        }
      }
    }
  }
`

export const getMedicalOpinionDetails = gql`
  query getMedicalOpinionDetails($medical_opinion_number: String) {
    applications {
      medicalOpinion {
        medicalOpinionDetails(medical_opinion_number: $medical_opinion_number) {
          recipientData {
            name
            card_number
            birth_date
            cpf
            phone_number
            service_number
            medical_opinion_number
            source_service
            medical_opinion_date
            releaseDate
            cooperated_name
            cooperated_crm
            medical_specialty_code
            medical_specialty
            cooperated_email
            cooperated_phone
            medical_opinion_type
          }
          medicalOpinionData {
            reason_consultation
            target_specialty
            target_specialty_code
          }
          authorizationData {
            authorization_code
          }
        }
      }
    }
  }
`

export const sendMedicalOpinionCounterReference = gql`
  mutation sendMedicalOpinionCounterReference(
    $medical_opinion_number: String,
    $contract: String,
    $target_specialty_code: String,
    $target_specialty: String,
    $cooperated_email: String,
    $crm: String,
    $cooperated_name: String,
    $clinical_summary: String,
    $diagnostic_hypothesis: String,
    $icd_primary: String,
    $icd_secondary: String,
    $conduct_guidelines: String,
    $outside_area_operation: String,
    $revaluation_period: String,
    $forwards_specialty: String,
  ) {
    applications {
      medicalOpinion {
        sendMedicalOpinion(
          medical_opinion_number: $medical_opinion_number,
          contract: $contract,
          target_specialty_code: $target_specialty_code,
          target_specialty: $target_specialty,
          cooperated_email: $cooperated_email,
          crm: $crm,
          cooperated_name: $cooperated_name,
          clinical_summary: $clinical_summary,
          diagnostic_hypothesis: $diagnostic_hypothesis,
          icd_primary: $icd_primary,
          icd_secondary: $icd_secondary,
          conduct_guidelines: $conduct_guidelines,
          outside_area_operation: $outside_area_operation,
          revaluation_period: $revaluation_period,
          forwards_specialty: $forwards_specialty,
        ) {
          medical_opinion_number
          number_sequence
          cooperated_code
          date_update
          username
          ds_opinion
          release_date
          ie_situation
          date_inactivation
          username_inactivation
          ds_justification
          number_sequence_signature
          number_sequence_signature_inactivation
          ie_status
          number_internal_sequence
          ds_utc_update
          ds_utc
          ie_summer_schedule
          number_sequence_regulation
          number_sequence_evaluation
          date_update_nrec
          username_nrec
          number_sequence_service_cons_pepa
          code_evolution
          number_sequence_form
          date_registration
          ds_justification_retro
        }
      }
    }
  }
`