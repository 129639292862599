import { combineReducers } from 'redux'
import auth from './modules/auth/reducer'
import token from './modules/token/reducer'
import faq from './modules/faq/reducer'
import customPage from './modules/customPage/reducer'
import paymentStatement from './modules/paymentStatement/reducer'
import paymentStatementCopan from './modules/paymentStatementCopan/reducer'
import unlockCards from './modules/unlockCards/reducer'
import medicalSchedule from './modules/medicalSchedule/reducer'
import webformSubmissions from './modules/webformSubmissions/reducer'
import publicCivilAction from './modules/publicCivilAction/reducer'
import billingReport from './modules/billingReport/reducer'
import billingReportFinancialResponsible from './modules/billingReportFinancialResponsible/reducer'
import readjustmentStatement from './modules/readjustmentStatement/reducer'
import employeeCards from './modules/employeeCards/reducer'
import quickAccess from './modules/quickAccess/reducer'
import codeCBO from './modules/codeCBO/reducer'
import outpatientAuthorizationConsultation from './modules/outpatientAuthorizationConsultation/reducer'
import benefitEligibility from './modules/benefitEligibility/reducer'
import pinSSCadastral from './modules/pinSSCadastral/reducer'
import pinSSUtilization from './modules/pinSSUtilization/reducer'
import outpatientAuthorizationsProviderResult from './modules/outpatientAuthorizationsProviderResult/reducer'
import atos from './modules/atos/reducer'
import referencedList from './modules/referencedList/reducer'
import triggerEvents from './modules/triggerEvents/reducer'
import recipientRegisterData from './modules/recipientRegister/reducer'
import medicalOpinion from './modules/medicalOpinion/reducer'
import declarations from './modules/declarations/reducer'

const rootReducer = combineReducers({
  auth,
  token,
  faq,
  customPage,
  paymentStatement,
  paymentStatementCopan,
  unlockCards,
  medicalSchedule,
  webformSubmissions,
  publicCivilAction,
  billingReport,
  readjustmentStatement,
  employeeCards,
  quickAccess,
  codeCBO,
  outpatientAuthorizationConsultation,
  benefitEligibility,
  pinSSCadastral,
  pinSSUtilization,
  outpatientAuthorizationsProviderResult,
  atos,
  referencedList,
  triggerEvents,
  recipientRegisterData,
  billingReportFinancialResponsible,
  medicalOpinion,
  declarations,
})

export default rootReducer
