import apiClient from 'api/config/apiClient'

export default {
  getMedicalSpecialties(payload) {
    return apiClient.request('cache-disable/medical_specialties', payload)
  },

  getRecipientForMedicalOpinion(payload) {
    return apiClient.request('cache-disable/recipient_for_medical_opinion', payload)
  },

  getMedicalOpinionsList(payload) {
    return apiClient.request('cache-disable/medical_opinions', payload)
  },

  getMedicalOpinionDetails(payload) {
    return apiClient.request('cache-disable/medical_opinion_details', payload)
  },

  sendMedicalOpinionCounterReference(payload) {
    return apiClient.request('cache-disable/medical_opinion_counter_reference', payload)
  },
}
