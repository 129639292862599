import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getMedicalSpecialties({ payload }) {
    try {
        yield put({ type: constants.MEDICAL_SPECIALTIES_LOADING, payload: true })
        const res = yield call(api.getMedicalSpecialties, payload)

        yield put({
            type: constants.MEDICAL_SPECIALTIES_DATA,
            payload: res,
        })

        yield put({ type: constants.MEDICAL_SPECIALTIES_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.MEDICAL_SPECIALTIES_ERROR, payload: e.message })
        yield put({ type: constants.MEDICAL_SPECIALTIES_LOADING, payload: false })
    }
}

function* getRecipientForMedicalOpinion({ payload }) {
    try {
        yield put({ type: constants.RECIPIENT_FOR_MEDICAL_OPINION_LOADING, payload: true })

        const res = yield call(api.getRecipientForMedicalOpinion, payload)

        yield put({
            type: constants.RECIPIENT_FOR_MEDICAL_OPINION_DATA,
            payload: res,
        })

        yield put({ type: constants.RECIPIENT_FOR_MEDICAL_OPINION_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.RECIPIENT_FOR_MEDICAL_OPINION_ERROR, payload: e.message })
        yield put({ type: constants.RECIPIENT_FOR_MEDICAL_OPINION_LOADING, payload: false })
    }
}

function* getMedicalOpinionsList({ payload }) {
    try {
        yield put({ type: constants.MEDICAL_OPINIONS_LIST_LOADING, payload: true })
        const res = yield call(api.getMedicalOpinionsList, payload)

        yield put({
            type: constants.MEDICAL_OPINIONS_LIST_DATA,
            payload: res,
        })

        yield put({ type: constants.MEDICAL_OPINIONS_LIST_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.MEDICAL_OPINIONS_LIST_ERROR, payload: e.message })
        yield put({ type: constants.MEDICAL_OPINIONS_LIST_LOADING, payload: false })
    }
}

function* getMedicalOpinionDetails({ payload }) {
    try {
        yield put({ type: constants.MEDICAL_OPINION_DETAILS_LOADING, payload: true })
        const res = yield call(api.getMedicalOpinionDetails, payload)

        yield put({
            type: constants.MEDICAL_OPINION_DETAILS_DATA,
            payload: res,
        })

        yield put({ type: constants.MEDICAL_OPINION_DETAILS_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.MEDICAL_OPINION_DETAILS_ERROR, payload: e.message })
        yield put({ type: constants.MEDICAL_OPINION_DETAILS_LOADING, payload: false })
    }
}

function* sendMedicalOpinionCounterReference({ payload }) {
    try {
        yield put({ type: constants.MEDICAL_OPINION_COUNTER_REFERENCE_LOADING, payload: true })
        const res = yield call(api.sendMedicalOpinionCounterReference, payload)

        yield put({
            type: constants.MEDICAL_OPINION_COUNTER_REFERENCE_DATA,
            payload: res,
        })

        yield put({ type: constants.MEDICAL_OPINION_COUNTER_REFERENCE_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.MEDICAL_OPINION_COUNTER_REFERENCE_ERROR, payload: e.message })
        yield put({ type: constants.MEDICAL_OPINION_COUNTER_REFERENCE_LOADING, payload: false })
    }
}

export default function* saga() {
    yield takeLatest(constants.GET_MEDICAL_SPECIALTIES, getMedicalSpecialties)
    yield takeLatest(constants.GET_RECIPIENT_FOR_MEDICAL_OPINION, getRecipientForMedicalOpinion)
    yield takeLatest(constants.GET_MEDICAL_OPINIONS_LIST, getMedicalOpinionsList)
    yield takeLatest(constants.GET_MEDICAL_OPINION_DETAILS, getMedicalOpinionDetails)
    yield takeLatest(constants.SEND_MEDICAL_OPINION_COUNTER_REFERENCE, sendMedicalOpinionCounterReference)
}