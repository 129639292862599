import React from 'react'

function If(props) {
    const {condition} = props
    if(condition) {
        return (
            props.children
        )
    }
    return false
}

export default If