import loadable from '@loadable/component'
// Molecules
const CarouselLinks = loadable(() => import('components/molecules/CarouselLinks'))
const ShareButtons = loadable(() => import('components/molecules/ShareButtons'))
// Organisms
const BasicBanner = loadable(() => import('components/organisms/BasicBanner'))
const NavBar = loadable(() => import('components/organisms/NavBar'))
const AccessMenu = loadable(() => import('components/organisms/AccessMenu'))
const Footer = loadable(() => import('components/organisms/Footer'))
const DataCards = loadable(() => import('components/organisms/DataCards'))
const TagBanners = loadable(() => import('components/organisms/TagBanners'))
const CarouselBigButton = loadable(() => import('components/organisms/CarouselBigButton'))
const SetBigButton = loadable(() => import('components/organisms/SetBigButton'))
const HalfBanners = loadable(() => import('components/organisms/HalfBanners'))
const LinkListLeftImage = loadable(() => import('components/organisms/LinkListLeftImage'))
const LatestNews = loadable(() => import('components/organisms/LatestNews'))
const PhoneTagsWithModal = loadable(() => import('components/organisms/PhoneTagsWithModal'))
const TextAndLinkWithRightImage = loadable(() => import('components/organisms/TextAndLinkWithRightImage'))
const PlanWidget = loadable(() => import('components/organisms/PlanWidget'))
const StepByStep = loadable(() => import('components/organisms/StepByStep'))
const MiniBanner = loadable(() => import('components/organisms/MiniBanner'))
const SearchWidget = loadable(() => import('components/organisms/SearchWidget'))
const TagTransparentBanners = loadable(() => import('components/organisms/TagTransparentBanners'))
const HtmlItemListImage = loadable(() => import('components/organisms/HtmlItemListImage'))
const InformationTags = loadable(() => import('components/organisms/InformationTags'))
const InformationTagWrapper = loadable(() => import('components/organisms/InformationTagWrapper'))
const Cards = loadable(() => import('components/organisms/Cards'))
const Form = loadable(() => import('components/organisms/Form'))
const TitleAndDescription = loadable(() => import('components/organisms/TitleAndDescription'))
const HalfBannersMosaic = loadable(() => import('components/organisms/HalfBannersMosaic'))
const InformationGrid = loadable(() => import('components/organisms/InformationGrid'))
const HighlightedNews = loadable(() => import('components/organisms/HighlightedNews'))
const FaqWidget = loadable(() => import('components/organisms/FaqWidget'))
const FaqList = loadable(() => import('components/organisms/FaqList'))
const Webform = loadable(() => import('components/organisms/Webform'))
const ImagesMosaic = loadable(() => import('components/organisms/ImagesMosaic'))
const ServiceWidget = loadable(() => import('components/organisms/ServiceWidget'))
const BigButtonsWithBanner = loadable(() => import('components/organisms/BigButtonsWithBanner'))
const StaffGallery = loadable(() => import('components/organisms/StaffGallery'))
const CarouselTagBanners = loadable(() => import('components/organisms/CarouselTagBanners'))
const PublicApp = loadable(() => import('components/organisms/PublicApp'))
const App = loadable(() => import('components/organisms/App'))
const BennerApplication = loadable(() => import('components/organisms/BennerApplication'))
const ExternalApplication = loadable(() => import('components/organisms/ExternalApplication'))
const BannerWithMosaic = loadable(() => import('components/organisms/BannerWithMosaicImages'))
const ContentList = loadable(() => import('components/organisms/ContentList'))
const DownloadList = loadable(() => import('components/organisms/DownloadList'))
const AudioGrid = loadable(() => import('components/organisms/AudioGrid'))
const MediaFull = loadable(() => import('components/organisms/MediaFull'))
const SectionTitle = loadable(() => import('components/organisms/SectionTitle'))
const TextCarouselWithImage = loadable(() => import('components/organisms/TextCarouselWithImage'))
const PhoneTags = loadable(() => import('components/organisms/PhoneTags/PhoneTagsLandingPage'))
const MediaCarousel = loadable(() => import('components/organisms/MediaCarousel'))
const Anchor = loadable(() => import('components/organisms/Anchor'))
const VideoGrid = loadable(() => import('components/organisms/VideoGrid'))
const ExtendedImagesGrid = loadable(() => import('components/organisms/ExtendedImagesGrid'))
const Countdown = loadable(() => import('components/organisms/Countdown'))
const Postits = loadable(() => import('components/organisms/PostIts'))
const SitemapWidget = loadable(() => import('components/organisms/SitemapWidget'))
const QuickAccess = loadable(() => import('components/organisms/QuickAccess'))
const LatestInformative = loadable(() => import('components/organisms/LatestInformative'))
const ScheduleWidgetProtocols = loadable(() => import('components/organisms/ScheduleWidgetProtocols'))
const ScheduleWidgetFees = loadable(() => import('components/organisms/ScheduleWidgetFees'))
const DownloadButton = loadable(() => import('components/organisms/DownloadButton'))
const AtosApplication = loadable(() => import('components/organisms/AtosApplication'))
const ImageGallery = loadable(() => import('components/organisms/ImageGallery'))
const Flipbook = loadable(() => import('components/organisms/Flipbook'))
const Map = loadable(() => import('components/organisms/Map'))
const NewsWidget = loadable(() => import('components/organisms/NewsWidget'))
const DefinitionWidget = loadable(() => import('components/organisms/DefinitionWidget'))
const GlossaryWidget = loadable(() => import('components/organisms/GlossaryWidget'))
const CronogramaPrestador = loadable(() => import('components/organisms/CronogramaPrestador'))
const PhotoGallery = loadable(() => import('components/organisms/PhotoGallery'))
const PhotoGalleryWidget = loadable(() => import('components/organisms/PhotoGalleryWidget'))
// Applications
const AcaoCivilPublica = loadable(() => import('components/templates/Applications/AcaoCivilPublica'))
const RelatorioFaturamento = loadable(() => import('components/templates/Applications/RelatorioFaturamento'))
const RelatorioFaturamentoResponsavelFinanceiro = loadable(() =>
  import('components/templates/Applications/RelatorioFaturamentoResponsavelFinanceiro')
)
const ExtratoReajuste = loadable(() => import('components/templates/Applications/ExtratoReajuste'))
const CartoesColaboradores = loadable(() => import('components/templates/Applications/CartoesColaboradores'))
const ConsultaCBO = loadable(() => import('components/templates/Applications/ConsutaCBO'))
const PinSSComponenteCadastral = loadable(() => import('components/templates/Applications/PinSSComponenteCadastral'))
const PinSSComponenteDeUtilizacao = loadable(() =>
  import('components/templates/Applications/PinSSComponenteDeUtilizacao')
)
const ElegibilidadeDoBeneficiario = loadable(() =>
  import('components/templates/Applications/ElegibilidadeDoBeneficiario')
)
const ConsultaAutorizacaoAmbulatorial = loadable(() =>
  import('components/templates/Applications/ConsultaAutorizacaoAmbulatorial')
)
const DemonstrativoPagamento = loadable(() => import('components/templates/Applications/DemonstrativoPagamento'))
const DemonstrativoPagamentoCopan = loadable(() =>
  import('components/templates/Applications/DemonstrativoPagamentoCopan')
)
const DesbloqueioCartao = loadable(() => import('components/templates/Applications/DesbloqueioCartao'))
const AgendaMedica = loadable(() => import('components/templates/Applications/AgendaMedica'))
const ListaReferencial = loadable(() => import('components/templates/Applications/ListaReferencial'))
const AprovacaoListaReferencial = loadable(() => import('components/templates/Applications/AprovacaoListaReferencial'))
const EventosDisparadores = loadable(() => import('components/templates/Applications/EventosDisparadores'))
const ParecerMedico = loadable(() => import('components/templates/Applications/ParecerMedico'))
const Contrarreferencia = loadable(() => import('components/templates/Applications/ParecerMedico/Contrarreferencia'))
const AtualizacaoCadastral = loadable(() => import('components/templates/Applications/AtualizacaoCadastral'))
const TabelaOPME = loadable(() => import('components/templates/Applications/TabelaOPME'))
const DeclaracaoBeneficiario = loadable(() => import('components/templates/Applications/Declaracao/DeclaracaoBeneficiario'))
const DeclaracaoCooperado = loadable(() => import('components/templates/Applications/Declaracao/DeclaracaoCooperado'))

// Templates
const News = loadable(() => import('components/templates/CustomPages/News'))
const Faq = loadable(() => import('components/templates/CustomPages/Faq'))
const Plan = loadable(() => import('components/templates/CustomPages/Plan'))
const Page = loadable(() => import('components/templates/CustomPages/Page'))
const Download = loadable(() => import('components/templates/CustomPages/Download'))
const Album = loadable(() => import('components/templates/CustomPages/Album'))

const compMapping = {
  NewsWidget,
  Flipbook,
  ImageGallery,
  Countdown,
  Postits,
  Anchor,
  MediaCarousel,
  PhoneTags,
  SectionTitle,
  TextCarouselWithImage,
  MediaFull,
  AudioGrid,
  BannerWithMosaic,
  ContentList,
  DownloadList,
  CarouselTagBanners,
  StaffGallery,
  BigButtonsWithBanner,
  ServiceWidget,
  ImagesMosaic,
  Footer,
  NavBar,
  BasicBanner,
  AccessMenu,
  HalfBanners,
  CarouselBigButton,
  SetBigButton,
  TagBanners,
  DataCards,
  LinkListLeftImage,
  LatestNews,
  PhoneTagsWithModal,
  TextAndLinkWithRightImage,
  PlanWidget,
  StepByStep,
  CarouselLinks,
  MiniBanner,
  TagTransparentBanners,
  SearchWidget,
  HtmlItemListImage,
  InformationTags,
  InformationTagWrapper,
  Cards,
  Form,
  TitleAndDescription,
  InformationGrid,
  HalfBannersMosaic,
  HighlightedNews,
  FaqWidget,
  FaqList,
  DemonstrativoPagamento,
  DemonstrativoPagamentoCopan,
  DesbloqueioCartao,
  AgendaMedica,
  Webform,
  App,
  PublicApp,
  BennerApplication,
  ExternalApplication,
  VideoGrid,
  AcaoCivilPublica,
  ExtendedImagesGrid,
  RelatorioFaturamento,
  RelatorioFaturamentoResponsavelFinanceiro,
  ExtratoReajuste,
  CartoesColaboradores,
  ConsultaCBO,
  PinSSComponenteCadastral,
  PinSSComponenteDeUtilizacao,
  SitemapWidget,
  QuickAccess,
  LatestInformative,
  ElegibilidadeDoBeneficiario,
  ScheduleWidgetProtocols,
  ScheduleWidgetFees,
  DownloadButton,
  ConsultaAutorizacaoAmbulatorial,
  AtosApplication,
  Map,
  News,
  Faq,
  Plan,
  Page,
  Download,
  ListaReferencial,
  AprovacaoListaReferencial,
  EventosDisparadores,
  DefinitionWidget,
  GlossaryWidget,
  AtualizacaoCadastral,
  TabelaOPME,
  CronogramaPrestador,
  PhotoGallery,
  PhotoGalleryWidget,
  Album,
  ShareButtons,
  ParecerMedico,
  Contrarreferencia,
  DeclaracaoBeneficiario,
  DeclaracaoCooperado,
}

export default compMapping
