export const SET_TOKEN = 'SET_TOKEN'
export const GET_TOKEN = 'GET_TOKEN'
export const GET_TOKEN_ERROR = 'GET_TOKEN_ERROR'
export const RESULT_TOKEN = 'RESULT_TOKEN'
export const LOADING_TOKEN = 'LOADING_TOKEN'

export const SET_USER = 'SET_USER'
export const SET_USER_DATA = 'SET_USER_DATA'
export const GET_USER = 'GET_USER'
export const GET_IP = 'GET_IP'
export const SET_IP = 'SET_IP'
export const RESULT_IP = 'RESULT_IP'
export const GET_USER_ERROR = 'GET_USER_ERROR'
export const RESULT_USER = 'RESULT_USER'
export const LOADING_USER = 'LOADING_USER'

export const GET_DATA = 'GET_DATA'
export const CLEAN_DATA = 'CLEAN_DATA'
export const GET_DATA_ERROR = 'GET_DATA_ERROR'
export const RESULT_DATA = 'RESULT_DATA'
export const LOADING_DATA = 'LOADING_DATA'

export const GET_HEADER_MENUS = 'GET_HEADER_MENUS'
export const GET_HEADER_MENUS_ERROR = 'GET_HEADER_MENUS_ERROR'
export const HEADER_MENUS_DATA = 'HEADER_MENUS_DATA'
export const LOADING_HEADER_MENUS = 'LOADING_HEADER_MENUS'

export const GET_APPLICATION_MENUS = 'GET_APPLICATION_MENUS'
export const GET_APPLICATION_MENUS_ERROR = 'GET_APPLICATION_MENUS_ERROR'
export const APPLICATION_MENUS_DATA = 'APPLICATION_MENUS_DATA'
export const LOADING_APPLICATION_MENUS = 'LOADING_APPLICATION_MENUS'

export const GET_FOOTER_MENUS = 'GET_FOOTER_MENUS'
export const FOOTER_MENUS_DATA = 'FOOTER_MENUS_DATA'
export const GET_FOOTER_MENUS_ERROR = 'GET_FOOTER_MENUS_ERROR'
export const LOADING_FOOTER_MENUS = 'LOADING_FOOTER_MENUS'

export const GET_FOOTER_SUB_MENUS = 'GET_FOOTER_SUB_MENUS'
export const FOOTER_SUB_MENUS_DATA = 'FOOTER_SUB_MENUS_DATA'
export const GET_FOOTER_SUB_MENUS_ERROR = 'GET_FOOTER_SUB_MENUS_ERROR'
export const LOADING_FOOTER_SUB_MENUS = 'LOADING_FOOTER_SUB_MENUS'

export const GET_ACCESS_MENUS = 'GET_ACCESS_MENUS'
export const ACCESS_MENUS_DATA = 'ACCESS_MENUS_DATA'
export const GET_ACCESS_MENUS_ERROR = 'GET_ACCESS_MENUS_ERROR'
export const LOADING_ACCESS_MENUS = 'LOADING_ACCESS_MENUS'

export const GET_PLANS = 'GET_PLANS'
export const PLANS_DATA = 'PLANS_DATA'
export const GET_PLANS_ERROR = 'GET_PLANS_ERROR'
export const LOADING_PLANS = 'LOADING_PLANS'

export const GET_PLAN_FILTERS = 'GET_PLAN_FILTERS'
export const PLAN_FILTERS_DATA = 'PLAN_FILTERS_DATA'
export const GET_PLAN_FILTERS_ERROR = 'GET_PLAN_FILTERS_ERROR'
export const LOADING_PLAN_FILTERS = 'LOADING_PLAN_FILTERS'

export const SEARCH_AUTOCOMPLETE = 'SEARCH_AUTOCOMPLETE'
export const SEARCH_AUTOCOMPLETE_DATA = 'SEARCH_AUTOCOMPLETE_DATA'
export const FETCH_SEARCH_AUTOCOMPLETE_ERROR = 'FETCH_SEARCH_AUTOCOMPLETE_ERROR'
export const LOADING_SEARCH_AUTOCOMPLETE = 'LOADING_SEARCH_AUTOCOMPLETE'

export const FETCH_SEARCH = 'FETCH_SEARCH'
export const FETCH_SEARCH_ERROR = 'FETCH_SEARCH_ERROR'
export const SEARCH_DATA = 'SEARCH_DATA'
export const LOADING_SEARCH = 'LOADING_SEARCH'

export const GET_FAQ_CATEGORY = 'GET_FAQ_CATEGORY'
export const FAQ_CATEGORY_DATA = 'FAQ_CATEGORY_DATA'
export const GET_FAQ_CATEGORY_ERROR = 'GET_FAQ_CATEGORY_ERROR'
export const LOADING_FAQ_CATEGORY = 'LOADING_FAQ_CATEGORY'

export const GET_PAYMENT_DEMONSTRATIVE_COPAN = 'GET_PAYMENT_DEMONSTRATIVE_COPAN'
export const PAYMENT_DEMONSTRATIVE_COPAN_DATA = 'PAYMENT_DEMONSTRATIVE_COPAN_DATA'
export const GET_PAYMENT_DEMONSTRATIVE_COPAN_ERROR = 'GET_PAYMENT_DEMONSTRATIVE_COPAN_ERROR'
export const PAYMENT_DEMONSTRATIVE_COPAN_LOADING = 'PAYMENT_DEMONSTRATIVE_COPAN_LOADING'

export const GET_PAYMENT_DEMONSTRATIVE_LOGS = 'GET_PAYMENT_DEMONSTRATIVE_LOGS'
export const PAYMENT_DEMONSTRATIVE_LOGS_DATA = 'PAYMENT_DEMONSTRATIVE_LOGS_DATA'
export const GET_PAYMENT_DEMONSTRATIVE_LOGS_ERROR = 'GET_PAYMENT_DEMONSTRATIVE_LOGS_ERROR'
export const PAYMENT_DEMONSTRATIVE_LOGS_LOADING = 'PAYMENT_DEMONSTRATIVE_LOGS_LOADING'


export const GET_PAYMENT_DEMONSTRATIVE_COPAN_LOGS = 'GET_PAYMENT_DEMONSTRATIVE_COPAN_LOGS'
export const PAYMENT_DEMONSTRATIVE_COPAN_LOGS_DATA = 'PAYMENT_DEMONSTRATIVE_COPAN_LOGS_DATA'
export const GET_PAYMENT_DEMONSTRATIVE_COPAN_LOGS_ERROR = 'GET_PAYMENT_DEMONSTRATIVE_COPAN_LOGS_ERROR'
export const PAYMENT_DEMONSTRATIVE_COPAN_LOGS_LOADING = 'PAYMENT_DEMONSTRATIVE_COPAN_LOGS_LOADING'


export const GET_PAYMENT_DEMONSTRATIVE = 'GET_PAYMENT_DEMONSTRATIVE'
export const PAYMENT_DEMONSTRATIVE_DATA = 'PAYMENT_DEMONSTRATIVE_DATA'
export const GET_PAYMENT_DEMONSTRATIVE_ERROR = 'GET_PAYMENT_DEMONSTRATIVE_ERROR'
export const PAYMENT_DEMONSTRATIVE_LOADING = 'PAYMENT_DEMONSTRATIVE_LOADING'
export const REMOVE_PAYMENT_DEMONSTRATIVE = 'REMOVE_PAYMENT_DEMONSTRATIVE'
export const PAYMENT_DEMONSTRATIVE_NO_DATA = 'PAYMENT_DEMONSTRATIVE_NO_DATA'

export const GET_CARDS_DATA = 'GET_CARDS_DATA'
export const CARDS_DATA = 'CARDS_DATA'
export const CARDS_ERROR = 'CARDS_ERROR'
export const CARDS_LOADING = 'CARDS_LOADING'

export const GET_CARD_RECIPIENT_DATA = 'GET_CARD_RECIPIENT_DATA'
export const CARD_RECIPIENT_DATA = 'CARD_RECIPIENT_DATA'
export const CARD_RECIPIENT_ERROR = 'CARD_RECIPIENT_ERROR'
export const CARD_RECIPIENT_LOADING = 'CARD_RECIPIENT_LOADING'

export const GET_UPDATE_CARD_RECIPIENT_DATA = 'GET_UPDATE_CARD_RECIPIENT_DATA'
export const UPDATE_CARD_RECIPIENT_DATA = 'UPDATE_CARD_RECIPIENT_DATA'
export const UPDATE_CARD_RECIPIENT_ERROR = 'UPDATE_CARD_RECIPIENT_ERROR'
export const UPDATE_CARD_RECIPIENT_LOADING = 'UPDATE_CARD_RECIPIENT_LOADING'

export const GET_UPDATE_RECIPIENT_REGISTER_DATA = 'GET_UPDATE_RECIPIENT_REGISTER_DATA'
export const UPDATE_RECIPIENT_REGISTER_DATA = 'UPDATE_RECIPIENT_REGISTER_DATA'
export const UPDATE_RECIPIENT_REGISTER_LOADING = 'UPDATE_RECIPIENT_REGISTER_LOADING'
export const UPDATE_RECIPIENT_REGISTER_ERROR = 'UPDATE_RECIPIENT_REGISTER_ERROR'
export const GET_RECIPIENT_REGISTER_DATA = 'GET_RECIPIENT_REGISTER_DATA'
export const RECIPIENT_REGISTER_DATA = 'RECIPIENT_REGISTER_DATA'
export const RECIPIENT_REGISTER_ERROR = 'RECIPIENT_REGISTER_ERROR'
export const RECIPIENT_REGISTER_LOADING = 'RECIPIENT_REGISTER_LOADING'

export const GET_SEARCH_ZIPCODE_DATA = 'GET_SEARCH_ZIPCODE_DATA'
export const SEARCH_ZIPCODE_DATA = 'SEARCH_ZIPCODE_DATA'
export const SEARCH_ZIPCODE_ERROR = 'SEARCH_ZIPCODE_ERROR'
export const SEARCH_ZIPCODE_LOADING = 'SEARCH_ZIPCODE_LOADING'

export const GET_SEARCH_ZIPCODE_REGISTER_DATA = 'GET_SEARCH_ZIPCODE_REGISTER_DATA'
export const SEARCH_ZIPCODE_REGISTER_DATA = 'SEARCH_ZIPCODE_REGISTER_DATA'
export const SEARCH_ZIPCODE_REGISTER_ERROR = 'SEARCH_ZIPCODE_REGISTER_ERROR'
export const SEARCH_ZIPCODE_REGISTER_LOADING = 'SEARCH_ZIPCODE_REGISTER_LOADING'

export const GET_WEBFORM_SUBMISSIONS = 'GET_WEBFORM_SUBMISSIONS'
export const WEBFORM_SUBMISSIONS_DATA = 'WEBFORM_SUBMISSIONS_DATA'
export const LOADING_WEBFORM_SUBMISSIONS = 'LOADING_WEBFORM_SUBMISSIONS'
export const ERROR_WEBFORM_SUBMISSIONS = 'ERROR_WEBFORM_SUBMISSIONS'

export const GET_CITIES = 'GET_CITIES'
export const CITIES_DATA = 'CITIES_DATA'
export const CITIES_LOADING = 'CITIES_LOADING'
export const CITIES_ERROR = 'CITIES_ERROR'

export const GET_SPECIALTIES = 'GET_SPECIALTIES'
export const SPECIALTIES_DATA = 'SPECIALTIES_DATA'
export const SPECIALTIES_LOADING = 'SPECIALTIES_LOADING'
export const SPECIALTIES_ERROR = 'SPECIALTIES_ERROR'

export const GET_MEDICAL_SCHEDULE = 'GET_MEDICAL_SCHEDULE'
export const MEDICAL_SCHEDULE_DATA = 'MEDICAL_SCHEDULE_DATA'
export const MEDICAL_SCHEDULE_LOADING = 'MEDICAL_SCHEDULE_LOADING'
export const MEDICAL_SCHEDULE_ERROR = 'MEDICAL_SCHEDULE_ERROR'

export const GET_RECIPIENTS = 'GET_RECIPIENTS'
export const RECIPIENTS_DATA = 'RECIPIENTS_DATA'
export const RECIPIENTS_LOADING = 'RECIPIENTS_LOADING'
export const RECIPIENTS_ERROR = 'RECIPIENTS_ERROR'
export const UPDATE_RECIPIENT_DATA = 'UPDATE_RECIPIENT_DATA'
export const GET_UPDATE_RECIPIENT_DATA = 'GET_UPDATE_RECIPIENT_DATA'
export const UPDATE_RECIPIENT_ERROR = 'UPDATE_RECIPIENT_ERROR'
export const UPDATE_RECIPIENT_LOADING = 'UPDATE_RECIPIENT_LOADING'

export const BILLING_REPORT_LOADING = 'BILLING_REPORT_LOADING'
export const GET_BILLING_REPORT = 'GET_BILLING_REPORT'
export const BILLING_REPORT_DATA = 'BILLING_REPORT_DATA'
export const BILLING_REPORT_ERROR = 'BILLING_REPORT_ERROR'
export const ORDER_BILLING_REPORT = 'ORDER_BILLING_REPORT'

export const READJUSTMENT_STATEMENT_LOADING = 'READJUSTMENT_STATEMENT_LOADING'
export const GET_READJUSTMENT_STATEMENT = 'GET_READJUSTMENT_STATEMENT'
export const READJUSTMENT_STATEMENT_DATA = 'READJUSTMENT_STATEMENT_DATA'
export const READJUSTMENT_STATEMENT_ERROR = 'READJUSTMENT_STATEMENT_ERROR'
export const ORDER_READJUSTMENT_STATEMENT = 'ORDER_READJUSTMENT_STATEMENT'

export const EMPLOYEE_CARDS_LOADING = 'EMPLOYEE_CARDS_LOADING'
export const GET_EMPLOYEE_CARDS = 'GET_EMPLOYEE_CARDS'
export const EMPLOYEE_CARDS_DATA = 'EMPLOYEE_CARDS_DATA'
export const EMPLOYEE_CARDS_ERROR = 'EMPLOYEE_CARDS_ERROR'

export const GET_QUICK_ACCESS_DATA = 'GET_QUICK_ACCESS_DATA'
export const QUICK_ACCESS_DATA = 'QUICK_ACCESS_DATA'
export const QUICK_ACCESS_ERROR = 'QUICK_ACCESS_ERROR'
export const QUICK_ACCESS_LOADING = 'QUICK_ACCESS_LOADING'

export const GET_CODE_CBO = 'GET_CODE_CBO'
export const CODE_CBO_DATA = 'CODE_CBO_DATA'
export const CODE_CBO_LOADING = 'CODE_CBO_LOADING'
export const CODE_CBO_ERROR = 'CODE_CBO_ERROR'

export const GET_OUTPATIENT_AUTHORIZATION_CONSULTATION = 'GET_OUTPATIENT_AUTHORIZATION_CONSULTATION'
export const OUTPATIENT_AUTHORIZATION_CONSULTATION_DATA = 'OUTPATIENT_AUTHORIZATION_CONSULTATION_DATA'
export const OUTPATIENT_AUTHORIZATION_CONSULTATION_LOADING = 'OUTPATIENT_AUTHORIZATION_CONSULTATION_LOADING'
export const OUTPATIENT_AUTHORIZATION_CONSULTATION_ERROR = 'OUTPATIENT_AUTHORIZATION_CONSULTATION_ERROR'

export const GET_BENEFIT_ELIGIBILITY = 'GET_BENEFIT_ELIGIBILITY'
export const BENEFIT_ELIGIBILITY_DATA = 'BENEFIT_ELIGIBILITY_DATA'
export const BENEFIT_ELIGIBILITY_LOADING = 'BENEFIT_ELIGIBILITY_LOADING'
export const BENEFIT_ELIGIBILITY_ERROR = 'BENEFIT_ELIGIBILITY_ERROR'

export const GET_RECIPIENT_DETAILS = 'GET_RECIPIENTS_DETAIL'
export const GET_RECIPIENT_DETAILS_DATA = 'GET_RECIPIENTS_DETAIL_DATA'
export const GET_RECIPIENT_DETAILS_LOADING = 'GET_RECIPIENTS_DETAIL_LOADING'
export const GET_RECIPIENT_DETAILS_ERROR = 'GET_RECIPIENTS_DETAIL_ERROR'

export const GET_RECIPIENTS_LIST = 'GET_RECIPIENTS_LIST'
export const GET_RECIPIENTS_LIST_DATA = 'GET_RECIPIENTS_LIST_DATA'
export const GET_RECIPIENTS_LIST_LOADING = 'GET_RECIPIENTS_LIST_LOADING'
export const GET_RECIPIENTS_LIST_ERROR = 'GET_RECIPIENTS_LIST_ERROR'

export const GET_RECIPIENTS_DATE = 'GET_RECIPIENTS_DATE'
export const GET_RECIPIENTS_DATE_DATA = 'GET_RECIPIENTS_DATE_DATA'
export const GET_RECIPIENTS_DATE_LOADING = 'GET_RECIPIENTS_DATE_LOADING'
export const GET_RECIPIENTS_DATE_ERROR = 'GET_RECIPIENTS_DATE_ERROR'

export const GET_PIN_UTILIZATION = 'GET_PIN_UTILIZATION'
export const GET_PIN_UTILIZATION_DATA = 'GET_PIN_UTILIZATION_DATA'
export const GET_PIN_UTILIZATION_LOADING = 'GET_PIN_UTILIZATION_LOADING'
export const GET_PIN_UTILIZATION_ERROR = 'GET_PIN_UTILIZATION_ERROR'

export const GET_BENNER_APPLICATIONS_BASE_URL = 'GET_BENNER_APPLICATIONS_BASE_URL'

export const GET_OUTPATIENT_AUTHORIZATION_PROVIDER_RESULT = 'GET_OUTPATIENT_AUTHORIZATION_PROVIDER_RESULT'
export const OUTPATIENT_AUTHORIZATION_PROVIDER_RESULT_DATA = 'OUTPATIENT_AUTHORIZATION_PROVIDER_RESULT_DATA'
export const OUTPATIENT_AUTHORIZATION_PROVIDER_LOADING = 'OUTPATIENT_AUTHORIZATION_PROVIDER_LOADING'
export const OUTPATIENT_AUTHORIZATION_PROVIDER_ERROR = 'OUTPATIENT_AUTHORIZATION_PROVIDER_ERROR'

export const GET_ATOS_LOGIN_DATA = 'GET_ATOS_LOGIN_DATA'
export const ATOS_LOGIN_DATA = 'ATOS_LOGIN_DATA'
export const ATOS_LOGIN_LOADING = 'ATOS_LOGIN_LOADING'
export const ATOS_LOGIN_ERROR = 'ATOS_LOGIN_ERROR'

export const GET_FOOTER_DATA = 'GET_FOOTER_DATA'
export const FOOTER_DATA = 'FOOTER_DATA'
export const FOOTER_DATA_ERROR = 'FOOTER_DATA_ERROR'

export const REFERENCED_LIST_LOADING = 'REFERENCED_LIST_LOADING'
export const GET_REFERENCED_LIST = 'GET_REFERENCED_LIST'
export const APPROVE_REFERENCED_LIST = 'APPROVE_REFERENCED_LIST'
export const REFERENCED_LIST_DATA = 'REFERENCED_LIST_DATA'
export const REFERENCED_LIST_ERROR = 'REFERENCED_LIST_ERROR'
export const ORDER_REFERENCED_LIST = 'ORDER_REFERENCED_LIST'

export const TRIGGER_EVENTS_LOADING = 'TRIGGER_EVENTS_LOADING'
export const GET_TRIGGER_EVENTS = 'GET_TRIGGER_EVENTS'
export const TRIGGER_EVENTS_DATA = 'TRIGGER_EVENTS_DATA'
export const TRIGGER_EVENTS_ERROR = 'TRIGGER_EVENTS_ERROR'
export const ORDER_TRIGGER_EVENTS = 'ORDER_TRIGGER_EVENTS'

export const BILLING_REPORT_FINANCIAL_RESPONSIBLE_LOADING = 'BILLING_REPORT_FINANCIAL_RESPONSIBLE_LOADING'
export const GET_BILLING_REPORT_FINANCIAL_RESPONSIBLE = 'GET_BILLING_REPORT_FINANCIAL_RESPONSIBLE'
export const BILLING_REPORT_FINANCIAL_RESPONSIBLE_DATA = 'BILLING_REPORT_FINANCIAL_RESPONSIBLE_DATA'
export const BILLING_REPORT_FINANCIAL_RESPONSIBLE_ERROR = 'BILLING_REPORT_FINANCIAL_RESPONSIBLE_ERROR'
export const ORDER_BILLING_REPORT_FINANCIAL_RESPONSIBLE = 'ORDER_BILLING_REPORT_FINANCIAL_RESPONSIBLE'

export const GET_MEDICAL_SPECIALTIES = 'GET_MEDICAL_SPECIALTIES'
export const MEDICAL_SPECIALTIES_LOADING = 'MEDICAL_SPECIALTIES_LOADING'
export const MEDICAL_SPECIALTIES_DATA = 'MEDICAL_SPECIALTIES_DATA'
export const MEDICAL_SPECIALTIES_ERROR = 'MEDICAL_SPECIALTIES_ERROR'

export const GET_RECIPIENT_FOR_MEDICAL_OPINION = 'GET_RECIPIENT_FOR_MEDICAL_OPINION'
export const RECIPIENT_FOR_MEDICAL_OPINION_LOADING = 'RECIPIENT_FOR_MEDICAL_OPINION_LOADING'
export const RECIPIENT_FOR_MEDICAL_OPINION_DATA = 'RECIPIENT_FOR_MEDICAL_OPINION_DATA'
export const RECIPIENT_FOR_MEDICAL_OPINION_ERROR = 'RECIPIENT_FOR_MEDICAL_OPINION_ERROR'

export const GET_MEDICAL_OPINIONS_LIST = 'GET_MEDICAL_OPINIONS_LIST'
export const MEDICAL_OPINIONS_LIST_LOADING = 'MEDICAL_OPINIONS_LIST_LOADING'
export const MEDICAL_OPINIONS_LIST_DATA = 'MEDICAL_OPINIONS_LIST_DATA'
export const MEDICAL_OPINIONS_LIST_ERROR = 'MEDICAL_OPINIONS_LIST_ERROR'

export const GET_MEDICAL_OPINION_DETAILS = 'GET_MEDICAL_OPINION_DETAILS'
export const MEDICAL_OPINION_DETAILS_DATA = 'MEDICAL_OPINION_DETAILS_DATA'
export const MEDICAL_OPINION_DETAILS_LOADING = 'MEDICAL_OPINION_DETAILS_LOADING'
export const MEDICAL_OPINION_DETAILS_ERROR = 'MEDICAL_OPINION_DETAILS_ERROR'

export const SEND_MEDICAL_OPINION_COUNTER_REFERENCE = 'SEND_MEDICAL_OPINION_COUNTER_REFERENCE'
export const MEDICAL_OPINION_COUNTER_REFERENCE_DATA = 'MEDICAL_OPINION_COUNTER_REFERENCE_DATA'
export const MEDICAL_OPINION_COUNTER_REFERENCE_LOADING = 'MEDICAL_OPINION_COUNTER_REFERENCE_LOADING'
export const MEDICAL_OPINION_COUNTER_REFERENCE_ERROR = 'MEDICAL_OPINION_COUNTER_REFERENCE_ERROR'
export const SET_NEW_COUNTER_REFERENCE_SPECIALTY = 'SET_NEW_COUNTER_REFERENCE_SPECIALTY'
export const CLEAR_NEW_COUNTER_REFERENCE_SPECIALTY = 'CLEAR_NEW_COUNTER_REFERENCE_SPECIALTY'

export const GET_DECLARATION_COOPERATED = 'GET_DECLARATION_COOPERATED'
export const GET_DECLARATION_COOPERATED_BANK = 'GET_DECLARATION_COOPERATED_BANK'
export const GET_DECLARATION_COOPERATED_RELATIONSHIP = 'GET_DECLARATION_COOPERATED_RELATIONSHIP'
export const GET_DECLARATION_COOPERATED_JUBILATED = 'GET_DECLARATION_COOPERATED_JUBILATED'
export const GET_DECLARATION_COOPERATED_PUBLIC_EXAM = 'GET_DECLARATION_COOPERATED_PUBLIC_EXAM'
export const GET_DECLARATION_COOPERATED_RETIRED = 'GET_DECLARATION_COOPERATED_RETIRED'
export const GET_DECLARATION_RECIPIENT_PORTABILITY = 'GET_DECLARATION_RECIPIENT_PORTABILITY'
export const DECLARATION_DATA = 'DECLARATION_DATA'
export const DECLARATION_ERROR = 'DECLARATION_ERROR'
export const DECLARATION_LOADING = 'DECLARATION_LOADING'
export const CLEAR_DECLARATION_DATA = 'CLEAR_DECLARATION_DATA'
