import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getTriggerEvents({ payload }) {
    try {
        yield put({ type: constants.TRIGGER_EVENTS_LOADING, payload: true })
        const res = yield call(api.getTriggerEvents, payload)

        yield put({
            type: constants.TRIGGER_EVENTS_DATA,
            payload: res,
        })

        yield put({ type: constants.TRIGGER_EVENTS_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.TRIGGER_EVENTS_ERROR, payload: e.message })
        yield put({ type: constants.TRIGGER_EVENTS_LOADING, payload: false })
    }
}

export default function* saga() {
    yield takeLatest(constants.GET_TRIGGER_EVENTS, getTriggerEvents)
}