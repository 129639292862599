import { gql } from 'graphql-request'

export default gql`
query getTriggerEvents($contract: String!, $st: String) {
  applications {
    triggerEvents {
      triggerEventsList(contract: $contract, st: $st) {
        reports {
          file
          file_extension
          contract
          date
        }
      }
    }
  }
}`
