import {
    GET_MEDICAL_SPECIALTIES,
    MEDICAL_SPECIALTIES_LOADING,
    MEDICAL_SPECIALTIES_DATA,
    MEDICAL_SPECIALTIES_ERROR,
    
    GET_RECIPIENT_FOR_MEDICAL_OPINION,
    RECIPIENT_FOR_MEDICAL_OPINION_LOADING,
    RECIPIENT_FOR_MEDICAL_OPINION_DATA,
    RECIPIENT_FOR_MEDICAL_OPINION_ERROR,
    
    GET_MEDICAL_OPINIONS_LIST,
    MEDICAL_OPINIONS_LIST_LOADING,
    MEDICAL_OPINIONS_LIST_DATA,
    MEDICAL_OPINIONS_LIST_ERROR,

    GET_MEDICAL_OPINION_DETAILS,
    MEDICAL_OPINION_DETAILS_DATA,
    MEDICAL_OPINION_DETAILS_LOADING,
    MEDICAL_OPINION_DETAILS_ERROR,

    SEND_MEDICAL_OPINION_COUNTER_REFERENCE,
    MEDICAL_OPINION_COUNTER_REFERENCE_DATA,
    MEDICAL_OPINION_COUNTER_REFERENCE_LOADING,
    MEDICAL_OPINION_COUNTER_REFERENCE_ERROR,
    SET_NEW_COUNTER_REFERENCE_SPECIALTY,
    CLEAR_NEW_COUNTER_REFERENCE_SPECIALTY,
} from 'store/constants'

export const initialState = {
    medicalSpecialties: {
        medicalSpecialtiesData: [],
        medicalSpecialtiesError: false,
        loading: false,
    },
    recipient: {
        recipientData: {
            name: '',
            card_number: '',
            birth_date: '',
            cpf: '',
        },
        recipientError: false,
        loading: false,
    },
    medicalOpinions: {
        medicalOpinionsData: [],
        medicalOpinionsError: false,
        loading: false,
    },
    medicalOpinionDetails: {
        recipientData: {},
        medicalOpinionData: {},
        authorizationData: {},
        medicalOpinionDetailsError: false,
        loading: false,
    },
    medicalOpinionCounterReference: {
        counterReferenceData: {},
        counterReferenceError: false,
        newCounterReferenceSpecialty: {},
        loading: false,
    }
}


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MEDICAL_SPECIALTIES:
            return {...state, medicalSpecialties: initialState.medicalSpecialties}
        case MEDICAL_SPECIALTIES_DATA:
            const medicalSpecialtiesData = action?.payload?.applications?.medicalOpinion?.medicalSpecialties
            return {...state, medicalSpecialties: { ...state.medicalSpecialties, medicalSpecialtiesError: false, medicalSpecialtiesData }}
        case MEDICAL_SPECIALTIES_LOADING:
            return {...state, medicalSpecialties: { ...state.medicalSpecialties, loading: action.payload }}
        case MEDICAL_SPECIALTIES_ERROR:
            return {...state, medicalSpecialties: { ...state.medicalSpecialties, medicalSpecialtiesError: action.payload, medicalSpecialtiesData: [] }}
                
        case GET_RECIPIENT_FOR_MEDICAL_OPINION:
            return {...state, recipient: initialState.recipient}
        case RECIPIENT_FOR_MEDICAL_OPINION_DATA:
            const recipientData = action?.payload?.applications?.medicalOpinion?.recipient
            return {...state, recipient: { ...state.recipient, recipientError: false, recipientData }}
        case RECIPIENT_FOR_MEDICAL_OPINION_LOADING:
            return {...state, recipient: { ...state.recipient, loading: action.payload }}
        case RECIPIENT_FOR_MEDICAL_OPINION_ERROR:
            const recipientDataError = { ...initialState?.recipient?.recipientData }
            return {...state, recipient: { ...state.recipient, recipientError: action.payload, recipientData: recipientDataError }}
                
        case GET_MEDICAL_OPINIONS_LIST:
            return {...state, medicalOpinions: initialState.medicalOpinions}
        case MEDICAL_OPINIONS_LIST_DATA:
            const medicalOpinionsData = action?.payload?.applications?.medicalOpinion?.medicalOpinions
            return {...state, medicalOpinions: { ...state.medicalOpinions, medicalOpinionsError: false, medicalOpinionsData }}
        case MEDICAL_OPINIONS_LIST_LOADING:
            return {...state, medicalOpinions: { ...state.medicalOpinions, loading: action.payload }}
        case MEDICAL_OPINIONS_LIST_ERROR:
            return {...state, medicalOpinions: { ...state.medicalOpinions, medicalOpinionsError: action.payload, medicalOpinionsData: [] }}
                    
        case GET_MEDICAL_OPINION_DETAILS:
            return {...state, medicalOpinionDetails: initialState.medicalOpinionDetails}
        case MEDICAL_OPINION_DETAILS_DATA:
            const medicalOpinionDetailsData = action?.payload?.applications?.medicalOpinion?.medicalOpinionDetails
            return { ...state, medicalOpinionDetails: { ...state.medicalOpinionDetails, medicalOpinionDetailsError: false, medicalOpinionDetailsData }}
        case MEDICAL_OPINION_DETAILS_LOADING:
            return {...state, medicalOpinionDetails: { ...state.medicalOpinionDetails, loading: action.payload }}
        case MEDICAL_OPINION_DETAILS_ERROR:
            return {...state, medicalOpinionDetails: { ...initialState.medicalOpinionDetails, medicalOpinionDetailsError: action.payload }}
                    
        case SEND_MEDICAL_OPINION_COUNTER_REFERENCE:
            return {...state, medicalOpinionCounterReference: initialState.medicalOpinionCounterReference}
        case MEDICAL_OPINION_COUNTER_REFERENCE_DATA:
            const counterReferenceData = action?.payload?.applications?.medicalOpinion?.sendMedicalOpinion
            return { ...state, medicalOpinionCounterReference: { ...state.medicalOpinionCounterReference, counterReferenceError: false, counterReferenceData }}
        case MEDICAL_OPINION_COUNTER_REFERENCE_LOADING:
            return {...state, medicalOpinionCounterReference: { ...state.medicalOpinionCounterReference, loading: action.payload }}
        case MEDICAL_OPINION_COUNTER_REFERENCE_ERROR:
            return {...state, medicalOpinionCounterReference: { ...initialState.medicalOpinionCounterReference, counterReferenceError: action.payload }}

        case SET_NEW_COUNTER_REFERENCE_SPECIALTY:
            return {...state, medicalOpinionCounterReference: { ...state.medicalOpinionCounterReference, newCounterReferenceSpecialty: action.payload }}

        case CLEAR_NEW_COUNTER_REFERENCE_SPECIALTY:
            return {...state, medicalOpinionCounterReference: { ...state.medicalOpinionCounterReference, newCounterReferenceSpecialty: {} }}

        default:
            return state
    }
}