import {
    GET_TRIGGER_EVENTS,
    TRIGGER_EVENTS_LOADING,
    TRIGGER_EVENTS_DATA,
    TRIGGER_EVENTS_ERROR,
} from 'store/constants'

export const initialState = {
    triggerEventsData: {},
    triggerEventsError: false,
    orderTriggerEvents: {},
    loading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TRIGGER_EVENTS:
            return { ...state, triggerEventsData: action.payload }
        case TRIGGER_EVENTS_DATA:
            return { ...state, triggerEventsData: action.payload }
        case TRIGGER_EVENTS_LOADING:
            return { ...state, loading: action.payload }
        case TRIGGER_EVENTS_ERROR:
            return { ...state, triggerEventsError: action.payload }
        default:
            return state
    }
}